<template>
  <fragment>
    <slot />
    <footer-bar />
  </fragment>
</template>

<script>
import FooterBar from "@/components/FooterBar";

export default {
  components: {
    FooterBar
  },
  data() {
    return {};
  }
};
</script>
