<template>
  <fragment>
    <v-app-bar class="noPrint" color="white" light app clipped-left flex>
      <v-app-bar-nav-icon
        @click="draw = !draw"
        v-if="!draw"
      ></v-app-bar-nav-icon>
      <v-spacer class="logospacer"></v-spacer>
      <div class="d-flex align-center">
        <v-img
          alt="timetracker Logo"
          class="shrink ml-5 mt-5"
          src="../assets/timetracker.png"
          contain
          transition="scale-transition"
          width="180"
        />
      </div>
      <v-spacer class="loginname"></v-spacer>
      <div
        v-if="currentUser"
        class="d-flex align-right ml-4 loginname"
        :title="
          'User: ' +
          currentUser.name +
          '\nMail: ' +
          currentUser.email +
          '\nRole: ' +
          currentUser.role
        "
      >
        <i class="fa fa-user-circle mr-2" aria-hidden="true"></i>
      </div>
    </v-app-bar>
    <v-navigation-drawer class="noPrint" v-model="draw" app clipped>
      <v-list dense class="grey mt-3 lighten-4" v-if="currentUser">
        <template v-for="(item, i) in items">
          <v-row v-if="item.heading" :key="i" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
          </v-row>
          <v-divider v-else-if="item.divider" :key="i" dark class="my-4" />
          <v-list-item
            v-else
            :key="i"
            :class="
              $route.path === '/' + item.action ||
              $route.path.includes(item.route)
                ? 'highlighted'
                : ''
            "
            active-class="highlighted"
            link
            @click="invokeNavElement(item.action)"
          >
            <v-list-item-action>
              <i :class="item.icon"></i>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="grey--text">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template v-slot:append>
        <footer-bar />
      </template>
    </v-navigation-drawer>
  </fragment>
</template>

<script>
import { bus } from "../main";
import FooterBar from "@/components/FooterBar";

export default {
  components: {
    FooterBar,
  },
  data() {
    return {
      currentUser: null,
      draw: null,
      items: [
        { icon: "fas fa-home", text: "Velkommen", action: "" },
        { heading: "Administration", condition: ["Admin"] },
        {
          icon: "fas fa-globe",
          text: "Afdelinger",
          action: "CreateLocation",
          route: "/CreateLocation",
          condition: ["Admin"],
        },
        {
          icon: "fas fa-clock",
          text: "Løndele",
          action: "CreateType",
          route: "/CreateType",
          condition: ["Admin"],
        },
        {
          icon: "fas fa-male",
          text: "Medarbejdere",
          action: "CreateEmployee",
          route: "/CreateEmployee",
          condition: ["Admin"],
        },
        {
          icon: "fas fa-id-card",
          text: "Brugere",
          action: "CreateUser",
          route: "/CreateUser",
          condition: ["Admin"],
        },
        { heading: "Tidsadministration", module: "TimeAdmin" },
        {
          icon: "fas fa-book",
          text: "Timeløn 14 dage",
          action: "TimeList",
          route: "/TimeList",
          module: "TimeAdmin",
        },
        { heading: "Tidsregistrering", module: "TimeRegistration" },
        {
          icon: "fas fa-book",
          text: "Uge Tidsskema",
          action: "TimeRegistration",
          route: "/TimeRegistration",
          module: "TimeRegistration",
        },
        { divider: true },
        { icon: "fas fa-sign-out-alt", text: "Log ud", action: "logout" },
      ],
    };
  },
  methods: {
    filterItems(currentUser) {
      this.items = this.items.filter(function (item) {
        return (
          (!item.condition ||
            (currentUser.role && item.condition.includes(currentUser.role))) &&
          (!item.module || currentUser.options["has" + item.module + "Module"])
        );
      });
    },
    checkModule(item) {
      return (
        !item.module || this.currentUser.options["has" + item.module + "Module"]
      );
    },
    invokeNavElement(action) {
      switch (action) {
        case "logout":
          this.logout();
          break;
        default:
          //assume its a link
          this.$router.push("/" + action).catch(() => {
            //window.location.reload();
          });
          break;
      }
    },
    async logout() {
      try {
        this.$http.post("/logout").then(() => {
          this.$store.commit("setUser", null);
          this.$router.push("login");
        });
      } catch {
        this.$router.push("login");
      }
    },
  },
  async created() {
    bus.$on("userSet", (currentUser) => {
      this.currentUser = currentUser;
      this.filterItems(currentUser);
    });
  },
};
</script>
<style>
.v-footer .companyname {
  min-width: 100% !important;
}
.v-toolbar__content .loginname {
  font-size: 26px;
}
@media screen and (max-width: 460px) {
  .v-toolbar__content .loginname {
    display: none !important;
  }
}
@media screen and (min-width: 1265px) {
  .logospacer {
    display: none !important;
  }
}
.v-navigation-drawer__content {
  background-color: #f5f5f5;
}
.highlighted {
  background-color: lightgray;
}
.highlighted .v-list-item__title.grey--text {
  color: gray !important;
}
.v-app-bar {
  overflow: hidden;
}
</style>
