import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "Login",
      meta: { layout: "no-navbar" },
      component: require("@/views/Login.vue").default,
    },
    {
      path: "/",
      name: "Home",
      meta: { requireAuth: true },
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/createemployee",
      name: "CreateEmployee",
      meta: { requireAuth: true },
      component: () => import("@/views/CreateEmployee.vue"),
    },
    {
      path: "/createlocation",
      name: "CreateLocation",
      meta: { requireAuth: true },
      component: () => import("@/views/CreateLocation.vue"),
    },
    {
      path: "/createuser",
      name: "CreateUser",
      meta: { requireAuth: true },
      component: () => import("@/views/CreateUser.vue"),
    },
    {
      path: "/createtype",
      name: "CreateType",
      meta: { requireAuth: true },
      component: () => import("@/views/CreateType.vue"),
    },
    {
      path: "/timeregistration",
      name: "TimeRegistration",
      meta: { requireAuth: true },
      component: () => import("@/views/TimeRegistration.vue"),
    },
    {
      path: "/timeregistration/:location/:year/:week",
      name: "TimeRegistration2",
      meta: { requireAuth: true },
      component: () => import("@/views/TimeRegistration.vue"),
    },
    {
      path: "/timelist",
      name: "TimeList",
      meta: { requireAuth: true },
      component: () => import("@/views/TimeList.vue"),
    },
    {
      path: "/timeadmin/:location/:year/:week",
      name: "TimeAdmin",
      meta: { requireAuth: true },
      component: () => import("@/views/TimeAdmin.vue"),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title || "Ivaldi - Timetracker";
  const signedIn = !!store.state.user;
  if (to.meta.requireAuth && !signedIn) {
    next("/login?return=" + encodeURIComponent(to.path));
  } else {
    next();
  }
});

export default router;
