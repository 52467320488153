<template>
  <v-main>
    <v-container fill-height justify-center>
      <v-form
        ref="login"
        class="login-form flex-grow-1"
        @submit.prevent="login()"
        v-show="showLoginForm"
      >
        <div>
          <img src="../assets/timetracker.png" />
        </div>
        <!-- <div class="mb-6 text-center">
        Få den samme adgangskode til alle skolens systemer. Log ind og skift
        herefter din adgangskode.
      </div> -->
        <v-text-field
          autofocus
          :label="usernameLabel"
          :rules="usernameRules"
          v-model="username"
          outlined
          required
          @focus="$event.target.select()"
        />
        <v-text-field
          type="password"
          :label="passwordLabel"
          :rules="passwordRules"
          v-model="password"
          outlined
          required
          @focus="$event.target.select()"
        />
        <v-btn block type="submit" :loading="loading" color="primary"
          >Login</v-btn
        >
        <v-alert type="error" color="#FF6251" class="mt-2" v-if="message">{{
          message
        }}</v-alert>
      </v-form>
      <h1 v-show="!showLoginForm">{{ message }}</h1>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      showLoginForm: false,
      username: null,
      usernameLabel: "Brugernavn",
      usernameRules: [(v) => !!v || "Brugernavn er påkrævet."],
      password: null,
      passwordLabel: "Adgangskode",
      passwordRules: [(v) => !!v || "Adgangskode er påkrævet."],
      loading: false,
      message: null,
    };
  },
  methods: {
    async login() {
      this.message = null;
      if (this.$refs.login.validate()) {
        this.loading = true;
        try {
          const { data } = await this.$http.post("/login", {
            username: this.username,
            password: this.password,
          });
          this.$store.commit("setUser", data);
          var defaultPath = "/";
          this.$router.replace(this.$route.params.return || defaultPath);
        } catch (error) {
          this.message =
            error?.response?.data?.message ||
            error?.response?.data ||
            error?.message ||
            error;
        }
        this.loading = false;
      }
    },
  },
  async created() {
    try {
      this.message = "Forbinder...";
      const response = await this.$http.get("/me");
      if (response.status === 204) {
        this.message = "Ingen forbindelse (204)";
      } else {
        //We are already logged in, thank you cookie
        this.$store.commit("setUser", response.data);
        var defaultPath = "/";
        this.$router.replace(
          this.$route.query.return || this.$route.params.return || defaultPath
        );
        this.message = null;
      }
    } catch (error) {
      //This is fine, we are just not logged in
      this.showLoginForm = true;
      this.message = null;
    }
    this.showLoginForm = true;
  },
};
</script>

<style lang="scss">
.login-form {
  max-width: 350px;
}
</style>
