import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@fortawesome/fontawesome-pro/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#c48c3b"
      }
    }
  },
  icons: {
    iconfont: "fa"
  }
});
