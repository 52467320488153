<template>
  <fragment>
    <nav-bar />
    <slot />
  </fragment>
</template>

<script>
import NavBar from "@/components/NavBar";

export default {
  components: {
    NavBar,
  },
};
</script>
