import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// Add VueFragment
import VueFragment from "vue-fragment";
Vue.use(VueFragment.Plugin);

// Add Axios
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios.create({ baseURL: "/api/v1" }));

// Add Layouts
import Default from "./layouts/Default.vue";
import NoNavbar from "./layouts/NoNavbar.vue";
Vue.component("default-layout", Default);
Vue.component("no-navbar-layout", NoNavbar);

Vue.config.productionTip = false;

export const bus = new Vue();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
